@import url(https://fonts.googleapis.com/css2?family=Mulish:wght@300&display=swap);
.container-interactive {
  font-size: 18px;
  background-color: var(--backgroundColor);
  padding-top: 150px; 
  margin-top: -150px; 
  text-align: center;
  background-size: cover;
  background-position: center;
}

.title {
  font-size: 22px;
  margin-top: 20px;
  font-weight: bold;
  color: var(--textViolet);
}

.heart-icon {
  width: 120px;
  height: 120px;
}

.info-icon {
  width: 80px;
  height: 61px;
}

.video {
  width: 800px;
  height: 500px;
}

.container-interactive p {
  margin-top: 20px;
  text-align: center;
}

.container-interactive .row .col {
  margin-bottom: 20px;
}

.container-interactive .row .col .interactive-info {
  text-align: justify;
}

.container-interactive .row .col-5 .interactive-info {
  text-align: justify;
}

.container-interactive .row .col-7 .interactive-info {
  text-align: justify;
}

@media (max-width: 768px) {
  .video {
    width: 100vw; 
    height: auto; 
  }

  .heart-icon {
    width: 120px;
    height: 120px;
  }
  
  .info-icon {
    width: 80px;
    height: 61px;
  }
}

@media (max-width: 480px) {
  .video {
    width: 350px; 
    height: 240px; 
  }

  .title {
    font-size: 18px;
  }

  .container-interactive p {
    font-size: 16px;
  }
}
* {
  font-family: 'Mulish', sans-serif;
  color: #222222;
  color: var(--textBlack);
  box-sizing: border-box !important;
}

body, html {
  margin: 0;
  padding: 0;
}

:root {
  --mainColor: #f6d1f1;
  --backgroundColor: rgba(243, 232, 241, 0.984);
  --textBlack: #222222;  
  --textViolet: #4b4e6d;
  --fuccia: #bf0991;
  --purple: #b2b6e6;
}
.lesson-cards {
  background-color: var(--backgroundColor);
  background-size: 100%;
  background-position: center;
}

.course-section {
  font-size: 18px;
  padding-top: 150px;
  margin-top: -100px;
  height: auto;
  margin-left: 100px;
  margin-right: 10px;
}

.custom-col {
  border: 2px solid black;
  margin-bottom: 20px; 
  margin-right: 50px; 
  margin-left: 50px; 
  border-radius: 80px; 
}

.p-with-top-margin {
  margin-top: 100px;
}

.courses-link {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.courses-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: left center;
          transform-origin: left center;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.courses-link:hover::after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

@media (max-width: 768px) {
  .p-with-top-margin {
    margin-top: 50px;
  }

  .course-section {
    margin-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 480px) {
  .course-section {
    margin-left: 10px;
    margin-right: 10px;
  }

  a.courses-link {
    font-size: 15px;
  }

  .p-with-top-margin {
    margin-top: 50px;
  }

  .custom-col {
    border: 2px solid black;
    margin-bottom: 20px; 
    width: 30% !important;
    border-radius: 80px; 
    margin-left: 10px;
    margin-right: 10px;
  }
}

.feedback {
  font-size: 18px;
  background-color: var(--backgroundColor);
  padding-top: 80px; 
  margin-top: -80px; 
}

.feedback-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.feedback-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  text-align: center;
  padding-left: 80px;
}

.feedback-image {
  margin-right: 50px;
  z-index: -1; 
}

.feedback-details .show-more, .show-less {
  background: none;
  border: none;
  color: var(--textBlack); 
  cursor: pointer;
  text-decoration: underline;
  padding: 0;
}

.feedback-image img {
  width: 400px;
  height: 400px;
  object-fit: cover;
  border-radius: 10%;
  margin-bottom: 10px;
}

.feedback-details .container {
  display: flex;
  flex-direction: column;
}

.feedback-details .row {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  justify-content: center;
}

.feedback-details .col {
  flex-basis: 30.33%;
  padding-right: 80px;
}

.feedback-details img {
  width: px; 
  height: 40px; 
  display: block;
  margin: 0 auto 10px;
}

.slick-prev,
.slick-next {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: none;
  z-index: 1;
  cursor: pointer;
  transition: background-color 0.3s;
}

.slick-prev:before,
.slick-next:before {
  content: '';
  display: inline-block;
  width: 30px;
  height: 30px;
  border-top: 6px solid var(--fuccia);
  border-right: 6px solid var(--fuccia);
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.slick-prev:before {
  -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg);
}

.slick-prev {
  left: 20px;
  margin-left: 20px; 
}

.slick-next {
  right: 20px;
  margin-right: 20px; 
}

.slick-prev:hover:before,
.slick-next:hover:before {
  border-color: var(--fuccia);
}

.slick-dots li button:before {
  font-size: 15px; 
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-right: 100px;
  margin-bottom: 0;
  padding-bottom: 50px; 
}

.button-container a.btn {
  -webkit-transform: none;
          transform: none;
  background-color: var(--purple); 
  color: var(--textBlack); 
}

.button-container a.btn:hover {
  background-color: var(--purple)
}

@media (max-width: 768px) {
  .feedback-content {
    flex-direction: column;
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
  }
  
  .feedback-image {
    margin-right: 0;
    margin-bottom: 20px;
  }
  
  .feedback-image img {
    width: 300px;
    height: 300px;
  }
  
  .button-container {
    justify-content: center;
    margin-right: 0;
  }

  .feedback-details .col {
    flex-basis: 50%; 
    padding-left: 80px;
    padding-right: 80px;
  }
}

@media (max-width: 480px) {
  .feedback-image img {
    width: 250px;
    height: 250px;
    padding-left: 5px;
    padding-right: 5px;
  }
  
  .button-container {
    margin-bottom: 20px;
  }

  .feedback-details .col {
    flex-basis: 100%; 
    padding-left: 80px;
    padding-right: 80px;
    text-align: justify;
  }

  .tutor_description p {
    font-size: 16px;
  }
}

.container-rules {
  font-size: 18px;
  background-color: var(--backgroundColor);
  padding-top: 150px; 
  margin-top: -150px; 
  text-align: center;
  background-size: cover;
  background-position: center;
}

.wavy-line {
  height: 50px;
  width: 100vw;
  background-image: url(/static/media/wavy-line.96144be7.png); 
  background-repeat: repeat-x;
  background-size: contain;
  background-position: bottom;
  margin-bottom: 70px;
}

.image {
  width: 330px;
  height: 300px;
  margin-right: 60px;
  margin-left: 60px;
}

.rule-column {
  margin-bottom: 50px;
}

.rule-column h2 {
  font-size: 22px;
  margin-bottom: 5px;
  font-weight: bold;
}

.rule-column p {
  text-align: justify;
}

@media (max-width: 768px) {
  .container-rules {
    padding-top: 100px;
    margin-top: -100px;
  }

  .rule-column {
    margin-bottom: 30px;
  }

  .image {
    width: 165px;
    height: 150px;
    margin-right: 0;
    margin-left: 0;
  }
}

@media (max-width: 480px) {
  .container-rules {
    padding-top: 80px;
    margin-top: -80px;
  }

  .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .rule-column {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
  }

  .rule-column p {
    font-size: 16px;
  }

  .rule-column h2 {
    font-size: 16px;
  }

  .image {
    width: 165px;
    height: 150px;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 150px;
  }
}

header {
	font-size: 22px;
	position: fixed;
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #f6d1f1;
	z-index: 3; 
}

header img {
	padding-left: 2rem;
	width: 170px;
  height: 150px; 
	z-index: 3; 
}

nav a.navigation {
	margin: 0 1rem;
	text-decoration: none;
}

nav a.navigation:hover {
	color: var(--fuccia);
}

header .nav-btn {
	padding: 5px;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	visibility: hidden;
	opacity: 0;
	font-size: 1.8rem;
}

header div,
nav {
	display: flex;
	align-items: center;
}

header a.btn {
	font-size: 22px;
  -webkit-transform: none;
          transform: none;
  background-color: var(--purple); 
  color: var(--textBlack); 
	z-index: 0;
}

header a.btn:hover {
  -webkit-transform: none;
          transform: none;
  background-color: var(--purple);
  color: var(--textBlack);
}

@media (max-width: 1024px) {
	header .nav-btn {
		visibility: visible;
		opacity: 1;
	}

	header nav {
		position: fixed;
		top: -100vh;
		left: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: var(--mainColor);
		height: 100%;
		width: 100vw;
		grid-gap: 1.5rem;
		gap: 1.5rem;
		transition: 1s;
		z-index: 0;
	}

	header .responsive_nav {
		-webkit-transform: translateY(100vh);
		        transform: translateY(100vh);
		z-index: 1;
	}

	nav .nav-close-btn {
		position: absolute;
		top: 2rem;
		right: 2rem;
	}

	nav a.navigation {
		font-size: 1.5rem;
	}

	header img {
		width: 140px;
		height: 120px; 
	}
}

@media (max-width: 480px) {

	header a.btn {
		font-size: 18px;
		-webkit-transform: none;
		        transform: none;
		background-color: var(--purple); 
		color: var(--textBlack); 
	}
}
.home-container {
  background-color: var(--backgroundColor);
  position: relative;
}

.background-image {
  margin-top: 150px;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}
.footer { 
  font-size: 18px;
  background-color: #f6d1f1;
}

.content_footer {
  display: flex;
  flex-direction: column;
}

.content_footer_links {
  display: flex;
  justify-content: space-between; 
  align-items: flex-start; 
  flex-direction: row;
  flex-wrap: wrap;
  width: 100vw;
  height: 100%;
  text-align: left; 
}

.content_footer_links_div { 
  margin: 1rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding-left: 50px;
  padding-right: 50px;
}

.socialmedia { 
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  flex-direction: row;
}
  
.content_footer-links_div p {
  line-height: 15px; 
  margin: 0.5rem 0; 
}

a {
  color: inherit;
  text-decoration: none;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

a:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2); 
}

@media (max-width: 480px) {

  .content_footer_links {
    justify-content: center;  
  }

  .content_footer_links_div { 
    padding-left: 10px;
    padding-right: 10px;
  }

  .content_footer {
    display: flex;
    flex-direction: row;
  }

	.footer { 
    font-size: 16px;
  }
}
.course-container {
  font-size: 18px;
  background-color: var(--backgroundColor);
  padding-top: 80px; 
  margin-top: -80px; 
  padding-bottom: 50px;
}

.course-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.course-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  text-align: center;
  padding-left: 80px;
}

.course-image {
  margin-right: 50px;
  z-index: 1; 
}

.important-icon {
  width: 70px !important;
  height: 70px !important;
}

.slick-dots li button:before {
  font-size: 15px; 
}

a.btn {
  -webkit-transform: none;
          transform: none;
  background-color: var(--purple);
  border-color: var(--purple);
  color: var(--textBlack);
  z-index: 1;
}

a.btn:hover {
  -webkit-transform: none;
          transform: none;
  background-color: var(--purple);
  border-color: var(--purple);
  color: var(--textBlack);
}

.course-details .show-more, .show-less {
  background: none;
  border: none;
  color: var(--textBlack); 
  cursor: pointer;
  text-decoration: underline;
  padding: 0;
}

.course-image img {
  width: 400px;
  height: 400px;
  object-fit: cover;
  border-radius: 10%;
  margin-bottom: 10px;
}

.course-details .container {
  display: flex;
  flex-direction: column;
}

.course-details .row {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  justify-content: center;
}

.course-details .col {
  flex-basis: 30.33%;
  padding-right: 80px;
}

.course-details img {
  width: px; 
  height: 40px; 
  display: block;
  margin: 0 auto 10px;
}

.slick-prev,
.slick-next {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: none;
  z-index: 1;
  cursor: pointer;
  transition: background-color 0.3s;
}

.slick-prev:before,
.slick-next:before {
  content: '';
  display: inline-block;
  width: 30px;
  height: 30px;
  border-top: 6px solid var(--fuccia);
  border-right: 6px solid var(--fuccia);
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.slick-prev:before {
  -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg);
}

.slick-prev {
  left: 20px;
  margin-left: 20px; 
}

.slick-next {
  right: 20px;
  margin-right: 20px; 
}

.slick-prev:hover:before,
.slick-next:hover:before {
  border-color: var(--fuccia);
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-right: 100px;
  margin-bottom: 0;
  padding-bottom: 50px; 
}

@media (max-width: 768px) {
  .course-content {
    flex-direction: column;
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
  }
  
  .course-image {
    margin-right: 0;
    margin-bottom: 20px;
  }
  
  .course-image img {
    width: 300px;
    height: 300px;
  }
  
  .button-container {
    justify-content: center;
    margin-right: 0;
  }

  .course-details .col {
    flex-basis: 50%; 
    padding-left: 80px;
    padding-right: 80px;
  }
}

@media (max-width: 480px) {
  .course-image img {
    width: 250px;
    height: 250px;
    padding-left: 5px;
    padding-right: 5px;
  }
  
  .button-container {
    margin-bottom: 20px;
  }

  .course-details .col {
    flex-basis: 100%; 
    padding-left: 80px;
    padding-right: 80px;
    text-align: justify;
  }

  .course_decription p {
    font-size: 16px;
  }
}

#back-header {
  font-size: 22px;
  position: relative;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  background-color: #f6d1f1;
  z-index: 1;
}

#back-header a {
  margin: 0 1rem;
  text-decoration: none;
}

#back-header a:hover {
  color: #bf0991;
}

.links {
  display: flex;
  align-items: center; 
}

#back-header img {
  width: 170px;
  height: 150px;
  margin-right: 1rem; 
}

@media (max-width: 768px) {
  #back-header {
      height: 150px;
      flex-direction: column;
      align-items: center;
      padding: 0 0; 
  }

  #back-header img {
    width: 115px;
    height: 95px;
    margin-top: 0.5rem;
  }

  #back-header a {
      margin-right: 1rem;
      margin-bottom: 1rem;
  }

  #back-header a {
    font-size: 16px;
  }
}

