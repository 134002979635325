.container-interactive {
  font-size: 18px;
  background-color: var(--backgroundColor);
  padding-top: 150px; 
  margin-top: -150px; 
  text-align: center;
  background-size: cover;
  background-position: center;
}

.title {
  font-size: 22px;
  margin-top: 20px;
  font-weight: bold;
  color: var(--textViolet);
}

.heart-icon {
  width: 120px;
  height: 120px;
}

.info-icon {
  width: 80px;
  height: 61px;
}

.video {
  width: 800px;
  height: 500px;
}

.container-interactive p {
  margin-top: 20px;
  text-align: center;
}

.container-interactive .row .col {
  margin-bottom: 20px;
}

.container-interactive .row .col .interactive-info {
  text-align: justify;
}

.container-interactive .row .col-5 .interactive-info {
  text-align: justify;
}

.container-interactive .row .col-7 .interactive-info {
  text-align: justify;
}

@media (max-width: 768px) {
  .video {
    width: 100vw; 
    height: auto; 
  }

  .heart-icon {
    width: 120px;
    height: 120px;
  }
  
  .info-icon {
    width: 80px;
    height: 61px;
  }
}

@media (max-width: 480px) {
  .video {
    width: 350px; 
    height: 240px; 
  }

  .title {
    font-size: 18px;
  }

  .container-interactive p {
    font-size: 16px;
  }
}