.lesson-cards {
  background-color: var(--backgroundColor);
  background-size: 100%;
  background-position: center;
}

.course-section {
  font-size: 18px;
  padding-top: 150px;
  margin-top: -100px;
  height: auto;
  margin-left: 100px;
  margin-right: 10px;
}

.custom-col {
  border: 2px solid black;
  margin-bottom: 20px; 
  margin-right: 50px; 
  margin-left: 50px; 
  border-radius: 80px; 
}

.p-with-top-margin {
  margin-top: 100px;
}

.courses-link {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.courses-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  transform: scaleX(0);
  transform-origin: left center;
  transition: transform 0.3s ease-in-out;
}

.courses-link:hover::after {
  transform: scaleX(1);
}

@media (max-width: 768px) {
  .p-with-top-margin {
    margin-top: 50px;
  }

  .course-section {
    margin-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 480px) {
  .course-section {
    margin-left: 10px;
    margin-right: 10px;
  }

  a.courses-link {
    font-size: 15px;
  }

  .p-with-top-margin {
    margin-top: 50px;
  }

  .custom-col {
    border: 2px solid black;
    margin-bottom: 20px; 
    width: 30% !important;
    border-radius: 80px; 
    margin-left: 10px;
    margin-right: 10px;
  }
}
