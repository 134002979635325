.container-rules {
  font-size: 18px;
  background-color: var(--backgroundColor);
  padding-top: 150px; 
  margin-top: -150px; 
  text-align: center;
  background-size: cover;
  background-position: center;
}

.wavy-line {
  height: 50px;
  width: 100vw;
  background-image: url('../img/wavy-line.png'); 
  background-repeat: repeat-x;
  background-size: contain;
  background-position: bottom;
  margin-bottom: 70px;
}

.image {
  width: 330px;
  height: 300px;
  margin-right: 60px;
  margin-left: 60px;
}

.rule-column {
  margin-bottom: 50px;
}

.rule-column h2 {
  font-size: 22px;
  margin-bottom: 5px;
  font-weight: bold;
}

.rule-column p {
  text-align: justify;
}

@media (max-width: 768px) {
  .container-rules {
    padding-top: 100px;
    margin-top: -100px;
  }

  .rule-column {
    margin-bottom: 30px;
  }

  .image {
    width: 165px;
    height: 150px;
    margin-right: 0;
    margin-left: 0;
  }
}

@media (max-width: 480px) {
  .container-rules {
    padding-top: 80px;
    margin-top: -80px;
  }

  .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .rule-column {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
  }

  .rule-column p {
    font-size: 16px;
  }

  .rule-column h2 {
    font-size: 16px;
  }

  .image {
    width: 165px;
    height: 150px;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 150px;
  }
}
