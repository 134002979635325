.feedback {
  font-size: 18px;
  background-color: var(--backgroundColor);
  padding-top: 80px; 
  margin-top: -80px; 
}

.feedback-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.feedback-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  text-align: center;
  padding-left: 80px;
}

.feedback-image {
  margin-right: 50px;
  z-index: -1; 
}

.feedback-details .show-more, .show-less {
  background: none;
  border: none;
  color: var(--textBlack); 
  cursor: pointer;
  text-decoration: underline;
  padding: 0;
}

.feedback-image img {
  width: 400px;
  height: 400px;
  object-fit: cover;
  border-radius: 10%;
  margin-bottom: 10px;
}

.feedback-details .container {
  display: flex;
  flex-direction: column;
}

.feedback-details .row {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  justify-content: center;
}

.feedback-details .col {
  flex-basis: 30.33%;
  padding-right: 80px;
}

.feedback-details img {
  width: px; 
  height: 40px; 
  display: block;
  margin: 0 auto 10px;
}

.slick-prev,
.slick-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: none;
  z-index: 1;
  cursor: pointer;
  transition: background-color 0.3s;
}

.slick-prev:before,
.slick-next:before {
  content: '';
  display: inline-block;
  width: 30px;
  height: 30px;
  border-top: 6px solid var(--fuccia);
  border-right: 6px solid var(--fuccia);
  transform: rotate(45deg);
}

.slick-prev:before {
  transform: rotate(-135deg);
}

.slick-prev {
  left: 20px;
  margin-left: 20px; 
}

.slick-next {
  right: 20px;
  margin-right: 20px; 
}

.slick-prev:hover:before,
.slick-next:hover:before {
  border-color: var(--fuccia);
}

.slick-dots li button:before {
  font-size: 15px; 
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-right: 100px;
  margin-bottom: 0;
  padding-bottom: 50px; 
}

.button-container a.btn {
  transform: none;
  background-color: var(--purple); 
  color: var(--textBlack); 
}

.button-container a.btn:hover {
  background-color: var(--purple)
}

@media (max-width: 768px) {
  .feedback-content {
    flex-direction: column;
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
  }
  
  .feedback-image {
    margin-right: 0;
    margin-bottom: 20px;
  }
  
  .feedback-image img {
    width: 300px;
    height: 300px;
  }
  
  .button-container {
    justify-content: center;
    margin-right: 0;
  }

  .feedback-details .col {
    flex-basis: 50%; 
    padding-left: 80px;
    padding-right: 80px;
  }
}

@media (max-width: 480px) {
  .feedback-image img {
    width: 250px;
    height: 250px;
    padding-left: 5px;
    padding-right: 5px;
  }
  
  .button-container {
    margin-bottom: 20px;
  }

  .feedback-details .col {
    flex-basis: 100%; 
    padding-left: 80px;
    padding-right: 80px;
    text-align: justify;
  }

  .tutor_description p {
    font-size: 16px;
  }
}
