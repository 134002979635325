header {
	font-size: 22px;
	position: fixed;
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #f6d1f1;
	z-index: 3; 
}

header img {
	padding-left: 2rem;
	width: 170px;
  height: 150px; 
	z-index: 3; 
}

nav a.navigation {
	margin: 0 1rem;
	text-decoration: none;
}

nav a.navigation:hover {
	color: var(--fuccia);
}

header .nav-btn {
	padding: 5px;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	visibility: hidden;
	opacity: 0;
	font-size: 1.8rem;
}

header div,
nav {
	display: flex;
	align-items: center;
}

header a.btn {
	font-size: 22px;
  transform: none;
  background-color: var(--purple); 
  color: var(--textBlack); 
	z-index: 0;
}

header a.btn:hover {
  transform: none;
  background-color: var(--purple);
  color: var(--textBlack);
}

@media (max-width: 1024px) {
	header .nav-btn {
		visibility: visible;
		opacity: 1;
	}

	header nav {
		position: fixed;
		top: -100vh;
		left: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: var(--mainColor);
		height: 100%;
		width: 100vw;
		gap: 1.5rem;
		transition: 1s;
		z-index: 0;
	}

	header .responsive_nav {
		transform: translateY(100vh);
		z-index: 1;
	}

	nav .nav-close-btn {
		position: absolute;
		top: 2rem;
		right: 2rem;
	}

	nav a.navigation {
		font-size: 1.5rem;
	}

	header img {
		width: 140px;
		height: 120px; 
	}
}

@media (max-width: 480px) {

	header a.btn {
		font-size: 18px;
		transform: none;
		background-color: var(--purple); 
		color: var(--textBlack); 
	}
}