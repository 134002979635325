#back-header {
  font-size: 22px;
  position: relative;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  background-color: #f6d1f1;
  z-index: 1;
}

#back-header a {
  margin: 0 1rem;
  text-decoration: none;
}

#back-header a:hover {
  color: #bf0991;
}

.links {
  display: flex;
  align-items: center; 
}

#back-header img {
  width: 170px;
  height: 150px;
  margin-right: 1rem; 
}

@media (max-width: 768px) {
  #back-header {
      height: 150px;
      flex-direction: column;
      align-items: center;
      padding: 0 0; 
  }

  #back-header img {
    width: 115px;
    height: 95px;
    margin-top: 0.5rem;
  }

  #back-header a {
      margin-right: 1rem;
      margin-bottom: 1rem;
  }

  #back-header a {
    font-size: 16px;
  }
}