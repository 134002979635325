.home-container {
  background-color: var(--backgroundColor);
  position: relative;
}

.background-image {
  margin-top: 150px;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}