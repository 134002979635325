.footer { 
  font-size: 18px;
  background-color: #f6d1f1;
}

.content_footer {
  display: flex;
  flex-direction: column;
}

.content_footer_links {
  display: flex;
  justify-content: space-between; 
  align-items: flex-start; 
  flex-direction: row;
  flex-wrap: wrap;
  width: 100vw;
  height: 100%;
  text-align: left; 
}

.content_footer_links_div { 
  margin: 1rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding-left: 50px;
  padding-right: 50px;
}

.socialmedia { 
  display: flex;
  gap: 1rem;
  flex-direction: row;
}
  
.content_footer-links_div p {
  line-height: 15px; 
  margin: 0.5rem 0; 
}

a {
  color: inherit;
  text-decoration: none;
  transition: transform 0.3s ease;
}

a:hover {
  transform: scale(1.2); 
}

@media (max-width: 480px) {

  .content_footer_links {
    justify-content: center;  
  }

  .content_footer_links_div { 
    padding-left: 10px;
    padding-right: 10px;
  }

  .content_footer {
    display: flex;
    flex-direction: row;
  }

	.footer { 
    font-size: 16px;
  }
}